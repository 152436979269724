@use '../utils/variables' as *;

/*particle system*/
.particle_system {
    z-index: -2;
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.particle_system_text {
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 1rem;
    font-weight: bold;
    color: $textColor;
    text-shadow: 1px 2px 0.5rem #0066ff;
    opacity: 0.2;
    animation: fade-out ease-in-out forwards;
    animation-duration: 1s;

    @media (prefers-reduced-motion) {
        animation: none !important;
    }
}